<template>
    <div class="Derlyconstruct">
        <div class="Derlyco_div">
            <div class="colle_Content">
                <div class="colle_input clearfix">
                    <strong class="fl">
                        创业项目是：
                    </strong>
                    <span class="fl">
                        <span class="span_span_inp">{{forms.project.name}}</span>
                    </span>
                </div>
                <div class="contess_div clearfix">
                    <ul class="ellipse_ul">
                        <li class="ellipse_left fl">
                            <div class="tit_div">
                                <i class="iconfonts icons-yonghu"></i>
                                <p>用户</p>
                            </div>
                            <div class="ellipse_left_div">
                                <div class="conten_l_div">
                                    <p class="conten_l_div_ps" v-for="(item,index) in forms.p1.values" :key="index">
                                        <span>{{index+1}}、{{item.value}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="conten_con_div">
                                <p class="conten_l_div_p" v-for="(item3,index3) in forms.p3.values" :key="index3">
                                    <span>{{index3+1}}、{{item3.value}}</span>
                                </p>
                            </div>
                        </li>
                        <li class="ellipse_right fl">
                            <div class="tit_div">
                                <i class="iconfonts icons-uf_customer"></i>
                                <p>客户</p>
                            </div>
                            <div class="ellipse_left_div">
                                <div class="conten_r_div">
                                    <p class="conten_l_div_pss" v-for="(item2,index2) in forms.p2.values" :key="index2">
                                        <span>{{index2+1}}、{{item2.value}}</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            forms:{
                project:{},
                p1:{
                    values:[]
                },
                p2:{
                    values:[]
                },
                p3:{
                    values:[]
                }
            }
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects4({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    var str = this.form
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveDCGJ.css';
</style>